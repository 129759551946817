export const RADIO_LOCATION = [
  {
    title: 'IGD',
    value: 'IGD',
  },
  {
    title: 'Ruang Rawat',
    value: 'Ruang Rawat',
  },
  {
    title: 'Lainnya',
    value: 'Lainnya',
  },
];

export const RADIO_LOCATION = [
  {
    title: 'ICU Dewasa',
    value: 'ICU Dewasa',
  },
  {
    title: 'ICU IGD',
    value: 'ICU IGD',
  },
  {
    title: 'ICU RS Lain',
    value: 'ICU RS Lain',
  },
  {
    title: 'HCU',
    value: 'HCU',
  },
  {
    title: 'Lainnya',
    value: 'Lainnya',
  },
];

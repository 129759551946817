export const SCIENTIFIC_TYPE = [
  {
    title: 'TP1',
    value: 'TP1',
  },
  {
    title: 'TP2',
    value: 'TP2',
  },
  {
    title: 'TP3',
    value: 'TP3',
  },
  {
    title: 'Laporan Kasus',
    value: 'LAPORAN_KASUS',
  },
  {
    title: 'Critical Appraisal',
    value: 'CRITICAL_APPRAISAL',
  },
  {
    title: 'Proposal',
    value: 'PROPOSAL',
  },
  {
    title: 'Tesis',
    value: 'TESIS',
  },
];

export const EXAM_LIST = [
  {
    title: 'UJIAN',
    value: 'UJIAN',
    path: '/exam/add',
  },
  {
    title: 'ALMAN',
    value: 'ALMAN',
    path: '/exam/add/alman',
  },
  {
    title: 'DOPS',
    value: 'DOPS',
    path: '/exam/add/dops',
  },
  {
    title: 'ACEX',
    value: 'ACEX',
    path: '/exam/add/acex',
  },
];

export const RADIO_LOCATION = [
  {
    title: 'ICU',
    value: 'ICU',
  },
  {
    title: 'Wards',
    value: 'Ruang Rawat',
  },
  {
    title: 'Lainnya',
    value: 'Lainnya',
  },
];

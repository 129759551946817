export const PENILAIAN_DIRI = [
  {
    title: 'Perlu Perbaikan',
    value: 'NEED_REMEDIAL',
  },
  {
    title: 'Cukup',
    value: 'ADEQUATE',
  },
  {
    title: 'Baik',
    value: 'GOOD',
  },
  {
    title: 'Sangat Baik',
    value: 'VERY_GOOD',
  },
];

export const PROCESS_EXAM = [
  {
    title: 'Ya',
    value: 'Ya',
  },
  {
    title: 'Tidak',
    value: 'Tidak',
  },
];

import { colors } from './colors';

export const customStyles = {
  headCells: {
    style: {
      color: colors.primaryPurple,
      fontWeight: 'bold',
    },
  },
};

export const RADIO_PENILAIAN_GROUP = [
  {
    title: 'Kurang Baik',
    value: 'VERY_BAD',
  },
  {
    title: 'Cukup',
    value: 'NOT_GOOD',
  },
  {
    title: 'Baik',
    value: 'GOOD',
  },
  {
    title: 'Sangat Baik',
    value: 'VERY_GOOD',
  },
];
